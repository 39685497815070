import { HSpacing, Link } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';

import { UserType } from 'lux/models/userType';
import { VacancySavedSearchItem } from 'lux/models/vacancySavedSearch';
import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    seeVacancies: 'autosearch.seevacancies',
    vacancies: {
        one: 'statistics.global.vacancy.one',
        some: 'statistics.global.vacancy.some',
        many: 'statistics.global.vacancy.many',
    },
    vacanciesNew: {
        one: 'autosearches.vacancy.new.one',
        some: 'autosearches.vacancy.new.some',
        many: 'autosearches.vacancy.new.many',
    },
};
interface VacancySavedSearchItemFooterProps {
    data: VacancySavedSearchItem;
}

const VacancySavedSearchItemFooter: TranslatedComponent<VacancySavedSearchItemFooterProps> = ({ trls, data }) => {
    const userType = useSelector((state) => state.userType);

    const {
        noNew,
        someNew,
        allNew,
        unknownSearch,
        searchArgs,
        sinceLastVisitSearchArgs,
        linkBase,
        withAddress,
        resultsAll,
        resultsNew,
    } = data;

    const newVacanciesLinkArgs = allNew ? searchArgs : sinceLastVisitSearchArgs;
    const vacanciesPath = `${linkBase}${withAddress ? paths.vacancySearchMap : paths.vacancySearch}`;
    const newVacanciesCount = allNew ? Number(resultsAll) : Number(resultsNew);

    if (userType === UserType.BackOffice) {
        return null;
    }

    return (
        <div className={styles.vacancySavedSearchItemFooter}>
            {(noNew || someNew) && (
                <>
                    <Link
                        href={`${vacanciesPath}?${searchArgs}&L_is_autosearch=true`}
                        data-qa="autosearch__results-counter_total"
                    >
                        {NumberFormatter.format(`${resultsAll}`, {
                            groupSeparator: NON_BREAKING_SPACE,
                        })}{' '}
                        <Conversion
                            value={Number(resultsAll)}
                            one={trls[TrlKeys.vacancies.one]}
                            some={trls[TrlKeys.vacancies.some]}
                            many={trls[TrlKeys.vacancies.many]}
                            hasValue={false}
                        />
                    </Link>
                    <HSpacing default={8} />
                </>
            )}
            {(someNew || allNew) && (
                <>
                    <Link
                        href={`${vacanciesPath}?${newVacanciesLinkArgs}&L_is_autosearch=true`}
                        style="positive"
                        data-qa="autosearch__results-counter_new"
                    >
                        {NumberFormatter.format(`${newVacanciesCount}`, {
                            groupSeparator: NON_BREAKING_SPACE,
                        })}{' '}
                        <Conversion
                            value={newVacanciesCount}
                            one={trls[TrlKeys.vacanciesNew.one]}
                            some={trls[TrlKeys.vacanciesNew.some]}
                            many={trls[TrlKeys.vacanciesNew.many]}
                            hasValue={false}
                        />
                    </Link>
                    <HSpacing default={8} />
                </>
            )}
            {unknownSearch && (
                <Link
                    href={`${vacanciesPath}?${searchArgs}&L_is_autosearch=true`}
                    data-qa="autosearch__results-counter_total"
                >
                    {trls[TrlKeys.seeVacancies]}
                </Link>
            )}
        </div>
    );
};

export default translation(VacancySavedSearchItemFooter);
