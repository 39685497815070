import { useDispatch } from 'react-redux';

import { GridColumn, GridRow } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { selectAllSavedSearchesAction } from 'lux/models/vacancySavedSearch';
import MagritteDefaultLayout from 'src/app/layouts/MagritteLayouts/DefaultLayout';
import PageLayout from 'src/app/layouts/PageLayout';
import ApplicantSideBar from 'src/components/ApplicantSideBar';
import { useNotification } from 'src/components/Notifications/Provider';
import SavedSearch from 'src/components/SavedSearch';
import ActivityButton from 'src/components/SavedSearch/Controls/ActivityButton';
import { LoadingActivityName } from 'src/components/SavedSearch/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import EmailMessage from 'src/pages/VacancySavedSearch/components/EmailMessage';
import VacancySavedSearchContent from 'src/pages/VacancySavedSearch/components/VacancySavedSearchContent';
import changeVacancySavedSearch from 'src/pages/VacancySavedSearch/components/changeVacancySavedSearch';

const TrlKeys = {
    title: 'savedSearches.searchVacancy',
};

const VacancySavedSearch: TranslatedComponent = ({ trls }) => {
    const { filterValue, savedSearch, isAllSelected, selectedItems, currentLoadingActivityName, paging } = useSelector(
        (state) => state.vacancySavedSearches
    );
    const { addNotification } = useNotification();
    const dispatch = useDispatch();

    const handleChange = (path: LoadingActivityName) => {
        void dispatch(changeVacancySavedSearch(addNotification, path));
    };

    const activityProps = {
        currentLoadingActivityName,
        selectedItems,
        handleChange,
    };

    return (
        <PageLayout title={trls[TrlKeys.title]} layout={MagritteDefaultLayout}>
            <GridRow>
                <GridColumn xs={4} s={8}>
                    <SavedSearch
                        title={trls[TrlKeys.title]}
                        message={<EmailMessage />}
                        isAllSelected={isAllSelected}
                        handleAllSelected={() => {
                            const checkedItems = isAllSelected ? [] : savedSearch.map(({ id }) => id);
                            dispatch(
                                selectAllSavedSearchesAction({
                                    isAllSelected: !isAllSelected,
                                    selectedItems: checkedItems,
                                })
                            );
                        }}
                        controls={[
                            <ActivityButton
                                key={LoadingActivityName.Subscribe}
                                activityName={LoadingActivityName.Subscribe}
                                style="accent"
                                mode="primary"
                                {...activityProps}
                            />,
                            <ActivityButton
                                key={LoadingActivityName.Unsubscribe}
                                activityName={LoadingActivityName.Unsubscribe}
                                mode="secondary"
                                {...activityProps}
                            />,
                            <ActivityButton
                                key={LoadingActivityName.Delete}
                                activityName={LoadingActivityName.Delete}
                                mode="secondary"
                                {...activityProps}
                            />,
                        ]}
                        filterValue={filterValue}
                        content={<VacancySavedSearchContent />}
                        paging={paging}
                    />
                </GridColumn>
                <GridColumn xs={0} l={1} />
                <GridColumn xs={4} l={3}>
                    <ApplicantSideBar isMagritte />
                </GridColumn>
            </GridRow>
        </PageLayout>
    );
};

export default translation(VacancySavedSearch);
