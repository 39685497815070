import { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
    selectVacancySavedSearchAction,
    VacancySavedSearchItem as VacancySavedSearchItemType,
} from 'lux/models/vacancySavedSearch';
import { useNotification } from 'src/components/Notifications/Provider';
import SavedSearchItem from 'src/components/SavedSearch/SavedSearchItem';
import { LoadingActivityName, RenameUrl } from 'src/components/SavedSearch/types';
import { useSelector } from 'src/hooks/useSelector';

import VacancySavedSearchItemContent from 'src/pages/VacancySavedSearch/components/VacancySavedSearchItemContent';
import VacancySavedSearchItemFooter from 'src/pages/VacancySavedSearch/components/VacancySavedSearchItemFooter';
import changeVacancySavedSearch from 'src/pages/VacancySavedSearch/components/changeVacancySavedSearch';

interface VacancySavedSearchItemProps {
    data: VacancySavedSearchItemType;
}

const VacancySavedSearchItem: FC<VacancySavedSearchItemProps> = ({ data }) => {
    const { savedSearch, selectedItems } = useSelector((state) => state.vacancySavedSearches);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const savedSearchId = data.id;

    const isChecked = selectedItems.includes(savedSearchId);

    const handleChange = () => {
        const checkedItems = isChecked
            ? selectedItems.filter((item) => item !== savedSearchId)
            : [...selectedItems, savedSearchId];
        dispatch(
            selectVacancySavedSearchAction({
                isAllSelected: savedSearch.length === checkedItems.length,
                selectedItems: checkedItems,
            })
        );
    };

    const subscribe = () => {
        void dispatch(changeVacancySavedSearch(addNotification, LoadingActivityName.Subscribe, [savedSearchId]));
    };

    return (
        <SavedSearchItem
            isChecked={isChecked}
            isSubscribeActive={data.emailActive}
            id={data.id}
            name={data.name}
            creationTimestamp={data.creationTimestamp}
            renameUrl={RenameUrl.Vacancy}
            handleChange={handleChange}
            subscribe={subscribe}
            content={<VacancySavedSearchItemContent data={data} />}
            footer={<VacancySavedSearchItemFooter data={data} />}
        />
    );
};

export default VacancySavedSearchItem;
