import { useState } from 'react';

import { Button, HSpacing, Input, Title, useBreakpoint } from '@hh.ru/magritte-ui';
import { PenOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { KeyCode } from 'bloko/common/constants/keyboard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { RenameUrl } from 'src/components/SavedSearch/types';
import translation from 'src/components/translation';
import fetcher from 'src/utils/fetcher';

import styles from './styles.less';

declare global {
    interface FetcherPostApi {
        [RenameUrl.Vacancy]: {
            queryParams: void;
            body: { id: string; name: string };
            response: void;
        };
        [RenameUrl.Resume]: {
            queryParams: void;
            body: { id: number; name: string };
            response: void;
        };
    }
}

const TrlKeys = {
    emptyName: 'savedSearch.emptyName',
    rename: 'savedSearch.rename',
    ok: 'savedSearch.ok',
};

interface NameProps {
    id: number;
    name: string;
    renameUrl: RenameUrl;
}

const Name: TranslatedComponent<NameProps> = ({ id, name, renameUrl, trls }) => {
    const [isEdit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [currentName, setCurrentName] = useState(name || '');

    const { addNotification } = useNotification();

    const { isXS } = useBreakpoint();

    const renameSavedSearch = () => {
        if (currentName === name) {
            setEdit(false);
            return;
        }

        setLoading(true);
        fetcher
            .postFormData(renameUrl, {
                id,
                name: currentName,
            })
            .catch((error) => {
                setCurrentName(name || '');
                defaultRequestErrorHandler(error, addNotification);
            })
            .finally(() => {
                setEdit(false);
                setLoading(false);
            });
    };

    if (isEdit) {
        return (
            <>
                <Input
                    value={currentName}
                    onChange={(value) => {
                        setCurrentName(value);
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === KeyCode.Enter) {
                            renameSavedSearch();
                        }
                    }}
                    data-qa="autosearch__input"
                />
                <HSpacing default={12} />
                <Button
                    onClick={renameSavedSearch}
                    mode="primary"
                    loading={isLoading}
                    style="accent"
                    data-qa="autosearch__ok"
                >
                    {isXS ? trls[TrlKeys.ok] : trls[TrlKeys.rename]}
                </Button>
            </>
        );
    }

    return (
        <>
            <Title Element="h2" size="medium" dataQaTitle="autosearch__title">
                {currentName || trls[TrlKeys.emptyName]}
            </Title>
            <HSpacing default={12} />
            <div className={styles.savedSearchItemIcon}>
                <PenOutlinedSize16 onClick={() => setEdit(true)} data-qa="autosearch__edit" />
            </div>
        </>
    );
};

export default translation(Name);
